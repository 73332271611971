import React, { useState } from 'react'
import Main from '../Layout/Main'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {  NavLink } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { login, saveinstantbiddetailsAction } from '../Redux/Action/AutheAction';
import Modal from 'react-bootstrap/Modal';
import { Form } from 'react-bootstrap';
export default function ValueReturned({ formData, setFormData, nextStep,prevStep }) {
  const [show,setShow] =useState(false)
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [loader,setloader]=useState(false)



const dispatch=useDispatch();

  const submitdata=()=>{
    formData.type=(formData.valuation>formData.g3valuation)?"TCGB":"G3"
    formData.price=(formData.valuation>formData.g3valuation)?formData.valuation:formData.g3valuation;
    formData.g3=formData.g3valuation;
    formData.tcgb=formData.valuation;
    formData.arrangecollection=1;

    
    
    setloader(true)
    
    dispatch(saveinstantbiddetailsAction(formData)).then(function(data){
      if(data.payload){
        nextStep()
        setloader(false)
      }
      
    })

  }




  return (
    <>
        {
      loader==true &&<div className='loader'>
    <img src={require('../Assets/Image/loader.svg').default} alt='loader'/>
    </div>
    }
    <Main>
    <div className='main-wrapper'>
       <Container>  
       <div className='back-arrow-part'>     
       <NavLink onClick={()=>{
          prevStep()
        }}><img src={require('../Assets/Image/back-arrow.svg').default} alt='check' />Back</NavLink>
       </div>
        <Row className="justify-content-center ">
          <Col xs={12} md={7} lg={7} xl={7} xxl={7}>
          <div className='box-shadow-card mb-3'>
            <div className='white-card'>
        

            <div className='card-heading-md-second text-center '> {formData.manufacturer +" "}{formData.model} </div>

            <div className='vrm-millage-group  mb-2'>    
            <div className='label-in'>{formData.year} {formData.fuel} {formData.colour}</div>
            <div className='vrm-value '>{formData.mileage} miles</div>
            </div> 
            <div className='label-in text-center mb-5'>{formData.vrm}</div>
        
              <Row className="justify-content-center ">
              <Col xs={12} md={4} lg={12} xl={12} xxl={12}> 
                <div className='form-part mb-4'>
                  <div className='form-label-show-bold text-center'>Our Best Trade Bid</div>
                 <div className='green-text-lg'>{  new Intl.NumberFormat('en-US', { style: 'currency',currency: 'GBP',}).format((formData.valuation>formData.g3valuation)?formData.valuation:formData.g3valuation)
                    }</div>
                 <div className='black-text-label' onClick={()=>{
              setShow(true)
              }}>This valuation is valid for 4 days</div>

     
                </div>




              </Col>   

              <Col xs={12} md={8} lg={8} xl={8} xxl={8}> 
              <div className='form-btn-part-second btn-overlap'>
            

           <Button type="submit" className='green-btn-square-form-second' onClick={()=>{
                submitdata()
              }}>Arrange Collection</Button>  
            
            
             
              </div>
              </Col>
              </Row> 
            </div>
            <div className='gry-bg-card m-minus-top mobile-padding'>
            <div className='gry-text-italic'>
              <p>Your other bids</p>
              <div className='list-italic'>
              <ul>
                <li>{  new Intl.NumberFormat('en-US', { style: 'currency',currency: 'GBP',}).format((formData.valuation>formData.g3valuation)?formData.g3valuation:formData.valuation)
                    }</li>
                <li>{  new Intl.NumberFormat('en-US', { style: 'currency',currency: 'GBP',}).format((formData.valuation>formData.g3valuation)?(formData.g3valuation-250):(formData.valuation-250))
                    }</li>
              </ul>
              </div>
            </div>
            <div className='gry-list-part'>
            <div className='gry-list-heading'>Our Assumptions</div>
            <ul className='gry-list-points'>
                <li>
                    <img src={require('../Assets/Image/check-green.svg').default} alt='check'/>
                    <p>It hasn’t got a personalised reg plate</p>
                </li>
                <li>
                    <img src={require('../Assets/Image/check-green.svg').default} alt='check'/>
                    <p>It is not an imported car</p>
                </li>
                <li>
                    <img src={require('../Assets/Image/check-green.svg').default} alt='check'/>
                    <p>It has two sets of keys</p>
                </li>
                <li>
                    <img src={require('../Assets/Image/check-green.svg').default} alt='check'/>
                    <p>It has no damage, aesthetic or functional</p>
                </li>
                <li>
                    <img src={require('../Assets/Image/check-green.svg').default} alt='check'/>
                    <p>There is at least 2 months till the MOT is due</p>
                </li>
                <li>
                    <img src={require('../Assets/Image/check-green.svg').default} alt='check'/>
                    <p>It has not been an insurance write-off</p>
                </li>
                <li>
                    <img src={require('../Assets/Image/check-green.svg').default} alt='check'/>
                    <p>It has not been used for hire or as a taxi</p>
                </li>
                <li>
                    <img src={require('../Assets/Image/check-green.svg').default} alt='check'/>
                    <p>It has not been used for driving tuition</p>
                </li>
                <li>
                    <img src={require('../Assets/Image/check-green.svg').default} alt='check'/>
                    <p>It has not been used as an emergency vehicle (e.g Police)</p>
                </li> 
            </ul>
            </div>
            </div>
            </div>
          </Col>
        </Row>

 
        </Container>    
    </div>

    </Main>
    </>
  )
}
