import React, { useEffect, useState } from 'react'
import Main from '../Layout/Main'
import { Button, Col, Container, Row } from 'react-bootstrap'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { MyinstantBidsvaluesdAction, saveinstantbiddetailsAction } from '../Redux/Action/AutheAction'

export default function Arrangecollections() {
    const [show,setShow] =useState(false)
    const [formData,setformData] =useState("")
const navigate=useNavigate();
    
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [loader,setloader]=useState(false)
  const {vrm}=useParams();
  useEffect(()=>{
dispatch(MyinstantBidsvaluesdAction({
    vrm:vrm
})).then(function(data){
    if(data.payload){
        setformData(data?.payload)
    }

})
  },[vrm])
  
  
  const dispatch=useDispatch();
  
    const submitdata=()=>{
      formData.type=(formData.tcgb>formData.g3)?"TCGB":"G3"
      formData.price=(formData.tcgb>formData.g3)?formData.tcgb:formData.g3;
      formData.g3=formData.g3;
      formData.tcgb=formData.tcgb;
      formData.arrangecollection=1;
  
      
      
      setloader(true)
      
      dispatch(saveinstantbiddetailsAction(formData)).then(function(data){
        if(data.payload){
         
          setloader(false)
          navigate(`/thankyou/${vrm}`)
        }
        
      })
  
    }
  return (
    <>
        {
      loader==true &&<div className='loader'>
    <img src={require('../Assets/Image/loader.svg').default} alt='loader'/>
    </div>
    }
    <Main>
    <div className='main-wrapper'>
       <Container>  
     
       <Row className="justify-content-center ">
          <Col xs={12} md={7} lg={7} xl={7} xxl={7}>
          <div className='box-shadow-card mb-3'>
            <div className='white-card'>
        

            <div className='card-heading-md-second text-center '> {formData.manufacturer +" "}{formData.model} </div>

            <div className='vrm-millage-group  mb-2'>    
            <div className='label-in'>{formData.year} {formData.fuel} {formData.colour}</div>
            <div className='vrm-value '>{formData.mileage} miles</div>
            </div> 
            <div className='label-in text-center mb-5'>{formData.vrm}</div>
        
              <Row className="justify-content-center ">
              <Col xs={12} md={4} lg={12} xl={12} xxl={12}> 
                <div className='form-part mb-4'>
                  <div className='form-label-show-bold text-center'>Our Best Trade Bid</div>
                 <div className='green-text-lg'>{  new Intl.NumberFormat('en-US', { style: 'currency',currency: 'GBP',}).format((formData.tcgb>formData.g3)?formData.tcgb:formData.g3)
                    }</div>
                 <div className='black-text-label' onClick={()=>{
              setShow(true)
              }}>This valuation is valid for 4 days</div>

     
                </div>




              </Col>   

              <Col xs={12} md={8} lg={8} xl={8} xxl={8}> 
              <div className='form-btn-part-second btn-overlap'>
            

           <Button type="submit" className='green-btn-square-form-second' onClick={()=>{
                submitdata()
              }}>Arrange Collection</Button>  
            
            
             
              </div>
              </Col>
              </Row> 
            </div>
            <div className='gry-bg-card m-minus-top mobile-padding'>
            <div className='gry-text-italic'>
              <p>Your other bids</p>
              <div className='list-italic'>
              <ul>
                <li>{  new Intl.NumberFormat('en-US', { style: 'currency',currency: 'GBP',}).format((formData.tcgb>formData.g3)?formData.g3:formData.tcgb)
                    }</li>
                <li>{  new Intl.NumberFormat('en-US', { style: 'currency',currency: 'GBP',}).format((formData.tcgb>formData.g3)?(formData.g3-250):(formData.tcgb-250))
                    }</li>
              </ul>
              </div>
            </div>
            <div className='gry-list-part'>
            <div className='gry-list-heading'>Our Assumptions</div>
            <ul className='gry-list-points'>
                <li>
                    <img src={require('../Assets/Image/check-green.svg').default} alt='check'/>
                    <p>It hasn’t got a personalised reg plate</p>
                </li>
                <li>
                    <img src={require('../Assets/Image/check-green.svg').default} alt='check'/>
                    <p>It is not an imported car</p>
                </li>
                <li>
                    <img src={require('../Assets/Image/check-green.svg').default} alt='check'/>
                    <p>It has two sets of keys</p>
                </li>
                <li>
                    <img src={require('../Assets/Image/check-green.svg').default} alt='check'/>
                    <p>It has no damage, aesthetic or functional</p>
                </li>
                <li>
                    <img src={require('../Assets/Image/check-green.svg').default} alt='check'/>
                    <p>There is at least 2 months till the MOT is due</p>
                </li>
                <li>
                    <img src={require('../Assets/Image/check-green.svg').default} alt='check'/>
                    <p>It has not been an insurance write-off</p>
                </li>
                <li>
                    <img src={require('../Assets/Image/check-green.svg').default} alt='check'/>
                    <p>It has not been used for hire or as a taxi</p>
                </li>
                <li>
                    <img src={require('../Assets/Image/check-green.svg').default} alt='check'/>
                    <p>It has not been used for driving tuition</p>
                </li>
                <li>
                    <img src={require('../Assets/Image/check-green.svg').default} alt='check'/>
                    <p>It has not been used as an emergency vehicle (e.g Police)</p>
                </li> 
            </ul>
            </div>
            </div>
            </div>
          </Col>
        </Row>

 
        </Container>    
    </div>

    </Main>
    </>
  )
}
