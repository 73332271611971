import React, { useEffect, useState } from 'react'
import Main from '../Layout/Main'
import { Button, Col, Container, Form, Row } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useFormik } from 'formik'
import * as Yup from 'yup';
import { profiledetailsAction, updateProfiledetailsAction } from '../Redux/Action/AutheAction'

export default function UpdateProfile() {
    const dispatch=useDispatch()

const [profiledetails, setProfiledetails] =useState("");
    useEffect(()=>{
      dispatch(profiledetailsAction()).then(function(object_data){
       
        setProfiledetails( object_data.payload)

        formik.setFieldValue('name',  object_data?.payload?.name)
        formik.setFieldValue('dealership_name',  object_data?.payload?.dealership_name)
        formik.setFieldValue('email',  object_data?.payload?.email)

        formik.setFieldValue('confirmemail',  object_data?.payload?.email)

        formik.setFieldValue('mobile_number',  object_data?.payload?.mobile_number?.replace(/^\+44/||/^\+91/, ''))
        
      })
    },[])




    const formik = useFormik({
      initialValues: {
        name:  "",
        dealership_name: "",
        email: "",
        confirmemail:  "",
        mobile_number:  "",
      },
      validationSchema: Yup.object({
        name: Yup.string().required('Name is required'),
        dealership_name: Yup.string().required('Dealership Name is required'),
        email: Yup.string().email().required('Email is required'),
        confirmemail:Yup .string().email().required('Confirm Email is required').oneOf([Yup.ref('email'), null], 'Email must match')  ,
        mobile_number: Yup.string().required('Mobile Number is required'),
      
      }),
      onSubmit: values => {

        values.mobile_number="+44"+values.mobile_number;
      dispatch(updateProfiledetailsAction(values))
        // Handle step 1 submission
    
      },
    });
  return (
   <Main>
        <div className='sub-header-part'>
           <Container>
           <ul className='sub-header-tab'>
                <li>
                    <NavLink to="/update-profile">My Details</NavLink>
                </li>
                <li>
                    <NavLink to="/reset-password">Password & Security</NavLink>
                </li>
            </ul>
           </Container>
        </div>
      <div className='main-wrapper'>
      <Container>     
        <Row className="justify-content-center">
          <Col xs={12} md={7} lg={7} xl={7} xxl={7}>
            <div className='white-card mb-5'>
              <div className='card-heading-md mb-5'>Update your details</div>         
              <Form  onSubmit={formik.handleSubmit}>
                <div className='form-part mb-4'>
                  <div className='form-label-show-bold'>Your Name</div>
                  <input type="text" placeholder='Type here' className='form-input-show' name='name'
                                         value={formik.values.name}   onChange={formik.handleChange}
                                          onBlur={formik.handleBlur}
                                           />
                  {formik.touched.name && formik.errors.name && (
                                          <div className='error'>{formik.errors.name}</div>
                                        )}                
                </div>
                <div className='form-part mb-4'>
                  <div className='form-label-show-bold'>Dealership Name</div>
                  <input type="text" placeholder='Type here'
                   className='form-input-show'   name='dealership_name'
                   value={formik.values.dealership_name}  
                    onChange={formik.handleChange}
                  onBlur={formik.handleBlur} />
                  {formik.touched.dealership_name && formik.errors.dealership_name && (
                                          <div className='error'>{formik.errors.dealership_name}</div>
                                        )}                
                </div>
                <div className='form-part mb-4'>
                  <div className='form-label-show-bold'>Email Address</div>
                  <input type="email" placeholder='email@email.com' className='form-input-show' name='email'  value={formik.values.email}  
                    onChange={formik.handleChange}
                  onBlur={formik.handleBlur} />
                  {formik.touched.email && formik.errors.email && (
                                          <div className='error'>{formik.errors.email}</div>
                                        )}                 
                </div>
                <div className='form-part mb-5'>
                  <div className='form-label-show-bold'>Confirm Email Address</div>
                  <input type="email" placeholder='email@email.com' className='form-input-show' value={formik.values.confirmemail}  
                    onChange={formik.handleChange}
                  onBlur={formik.handleBlur} />
                  {formik.touched.confirmemail && formik.errors.confirmemail && (
                                          <div className='error'>{formik.errors.confirmemail}</div>
                                        )}                
                </div>
                <div className='form-part mb-4'>
                  <div className='form-label-show-bold'>Your Mobile Number</div>
                  <div className='sub-label-show'>We’ll send you a text message to confirm your account</div>
                  <div className="input-group group-input-signup">
                  <div className="input-group-prepend">
                    <span className="input-group-text" >+44</span>
                  </div>                
                  <input type="text"  className='group-input-signup' name='mobile_number' value={formik.values.mobile_number}  
                    onChange={formik.handleChange}
                  onBlur={formik.handleBlur} />  
                </div>
                {formik.touched.mobile_number && formik.errors.mobile_number && (
                                          <div className='error'>{formik.errors.mobile_number}</div>
                                        )}
                 
                </div> 

                {/* <div className='form-part mb-4'>
                  <div className='form-label-show-bold'>Your Mobile Number</div>
                  <div className='sub-label-show'>We’ll send you a text message to confirm your account</div>
                  <input type="text" placeholder='+44' className='form-input-show' />                 
                </div>               */}

                <div className='form-btn-part'>               
                <button type="submit" className='green-btn-square-form'>Update</button>   
                </div>                            
              </Form>
            </div>
          </Col>        
        </Row>  
      </Container>
      </div> 
   </Main>
  )
}
