import React, { useState } from 'react'
import Main from '../Layout/Main'
import { Button, Col, Container, Form, Row } from 'react-bootstrap'
import { NavLink } from 'react-router-dom';
import { useFormik } from 'formik'
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import Icon from '@mdi/react';
import { mdiEyeOutline } from '@mdi/js';
import { mdiEyeOffOutline } from '@mdi/js';
import { changePasswordAction } from '../Redux/Action/AutheAction';
export default function ResetPassword() {
  const [password_text, Setpassword] = useState('password');
    const dispatch=useDispatch()
    const formik = useFormik({
      initialValues: {
        old_password:  '',
        password:  '',
        confirmpassword:'',
      },
      validationSchema: Yup.object({
        old_password: Yup.string().required('Old Password is required'),
        password: Yup.string().required('Password is required'),
        confirmpassword:Yup .string().required('Confirm Password is required').oneOf([Yup.ref('password'), null], 'Password must match') 
       
      }),
      onSubmit: (values) => {
 
      dispatch(changePasswordAction(values))
     
  
      
      },
    });

    const password_hide_show = () => {
      Setpassword(password_text == 'input' ? 'password' : 'input');
    };


    console.log(formik.errors)


  return (
    <Main>
    <div className='sub-header-part'>
       <Container>
       <ul className='sub-header-tab'>
            <li>
                <NavLink to="/update-profile">My Details</NavLink>
            </li>
            <li>
                <NavLink to="/reset-password">Password & Security</NavLink>
            </li>
        </ul>
       </Container>
    </div>
  <div className='main-wrapper'>
  <Container>     
    <Row className="justify-content-center">
      <Col xs={12} md={7} lg={7} xl={7} xxl={7}>
        <div className='white-card mb-5'>
          <div className='card-heading-md mb-5'>Update your details</div>         
          <Form  onSubmit={formik.handleSubmit} >
            <div className='form-part mb-4'>
              <div className='form-label-show-bold'>Old Password</div>
              <div className='password-group-input'> <input  type={password_text} placeholder='******' className='form-input-show' name='old_password'  value={formik.values.old_password}  
                    onChange={formik.handleChange}
                  onBlur={formik.handleBlur} />
                  {formik.touched.old_password && formik.errors.old_password && (
                                          <div className='error'>{formik.errors.old_password}</div>
                                        )}   
          {password_text=="password"?<Icon className='eye-password' path={mdiEyeOffOutline} size={1}  onClick={() => password_hide_show()} />:<Icon className='eye-password' path={mdiEyeOutline} size={1}  onClick={() => password_hide_show()}  />}                 
          </div>            
            </div>
            <div className='form-part mb-4'>
              <div className='form-label-show-bold'>New Password</div>
              <div className='password-group-input'>
 
              <input  type={password_text} placeholder='******' className='form-input-show' name='password'  value={formik.values.password}  
                    onChange={formik.handleChange}
                  onBlur={formik.handleBlur} />
                  {formik.touched.password && formik.errors.password && (
                                          <div className='error'>{formik.errors.password}</div>    )}
          {password_text=="password"?<Icon className='eye-password' path={mdiEyeOffOutline} size={1}  onClick={() => password_hide_show()} />:<Icon className='eye-password' path={mdiEyeOutline} size={1}  onClick={() => password_hide_show()}  />}                 
          </div>            
            </div>
            <div className='form-part mb-4'>
              <div className='form-label-show-bold'>Confirm New Password</div>
              <div className='password-group-input'>
      
              
                  <input  type={password_text} placeholder='******' className='form-input-show' name='confirmpassword'  value={formik.values.confirmpassword}  
                    onChange={formik.handleChange}
                  onBlur={formik.handleBlur} />
                  {formik.touched.confirmpassword && formik.errors.confirmpassword && (
                                          <div className='error'>{formik.errors.confirmpassword}</div>    )}    
              {password_text=="password"?<Icon className='eye-password' path={mdiEyeOffOutline} size={1}  onClick={() => password_hide_show()} />:<Icon className='eye-password' path={mdiEyeOutline} size={1}  onClick={() => password_hide_show()}  />}                 
              </div>   


            </div>    

            <div className='form-btn-part'>               
            <button type="submit" className='green-btn-square-form'>Update</button>   
            </div>                            
          </Form>
        </div>
      </Col>        
    </Row>  
  </Container>
  </div> 
</Main>
  )
}
