import { createSlice } from '@reduxjs/toolkit';

import { login, myinstantBidsgetAction } from '../Action/AutheAction';
const initialState={
   token:"",
    myinstantbids:[]
}


const AuthSlice=createSlice(
{
    name: 'myinstantBidsget',
    initialState,
    reducers: { }  ,
    extraReducers: (any)=>{
        any.addCase(myinstantBidsgetAction.fulfilled,(state, {payload}) => {
            if (payload) {
                state.myinstantbids=payload
              }
          })

          any.addCase(login.fulfilled,(state, {payload}) => {
            if (payload) {
                state.token=payload
              }

          })

    }

}
)


export default AuthSlice.reducer;
