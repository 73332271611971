import React, { useState } from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Main from '../Layout/Main';
import { Form } from 'react-bootstrap';
import { NavLink, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { UpdatepasswordAction } from '../Redux/Action/AutheAction';
import Icon from '@mdi/react';
import { mdiEyeOutline } from '@mdi/js';
import { mdiEyeOffOutline } from '@mdi/js';
import { toast } from 'react-toastify';
export default function PasswordRegistration({ formData, setFormData, nextStep }) {
  const [password_text, Setpassword] = useState('password');
  const dispatch=useDispatch()
  const navigate=useNavigate()
  const formik = useFormik({
    initialValues: {
      password: formData.password || '',
      confirmpassword: formData.confirmpassword || '',
    },
    validationSchema: Yup.object({
      password: Yup.string().required('Password is required'),
      confirmpassword:Yup .string().required('Confirm Password is required').oneOf([Yup.ref('password'), null], 'Password must match') 
     
    }),
    onSubmit: values => {
      // Handle step 1 submission

      values.user_id=formData.user_id;

      dispatch(UpdatepasswordAction(values)).then(function(data){
        if(data.payload){
          window.location.href="/dashboard"
       
        
      }
      })

    },
  });
  const password_hide_show = () => {
    Setpassword(password_text == 'input' ? 'password' : 'input');
  };
  return (
    <Main>
     <div className='main-wrapper'>
       <Container>  
       {/* <div className='back-arrow-part'>     
        <NavLink onClick={()=>{
          setFormData({})
          nextStep()
        }}><img src={require('../Assets/Image/back-arrow.svg').default} alt='check'/>Back</NavLink>
       </div> */}
        <Row className="justify-content-center">
          <Col xs={12} md={7} lg={7} xl={7} xxl={7}>
            <div className='white-card'>
              <div className='card-heading-md'>Trade123 it’s shockingly simple</div>
              <div className='card-pragraph-md'>Lastly, create a password.</div>
              <div className='card-pragraph-md'>Enter it below to continue</div>
            {/* Step Follow */} 
              <ul className='step-follow'>
                <li>
                  <div className='number-show fill-circle'>1</div>
                  <div className='step-title'>Your Details</div>
                </li>
                <li>
                  <div className='number-show fill-circle'>2</div>
                  <div className='step-title'>Verification</div>
                </li>
                <li>
                  <div className='number-show fill-circle'>3</div>
                  <div className='step-title'>Security</div>
                </li>
              </ul>
              <Form onSubmit={formik.handleSubmit}>
                <div className='form-part mb-4'>
                  <div className='form-label-show-bold'>Create a Password</div>
                  <div className='password-group-input'>
                  <input  type={password_text} placeholder='Enter your password here' className='form-input-show' name='password'  value={formik.values.password}  
                    onChange={formik.handleChange}
                  onBlur={formik.handleBlur} />
                  {formik.touched.password && formik.errors.password && (
                                          <div className='error'>{formik.errors.password}</div>
                                        )}
                                        {password_text=="password"?<Icon className='eye-password' path={mdiEyeOffOutline} size={1}  onClick={() => password_hide_show()} />:<Icon className='eye-password' path={mdiEyeOutline} size={1}  onClick={() => password_hide_show()}  />}                 
          </div>  

                </div>

                <div className='form-part mb-4'>
                  <div className='form-label-show-bold'>Re-Enter Password</div>
            <div className='password-group-input'>
      <input  type={password_text} placeholder='******' className='form-input-show'   name='confirmpassword'  value={formik.values.confirmpassword}  
                    onChange={formik.handleChange}
                  onBlur={formik.handleBlur}  />     

      {formik.touched.confirmpassword && formik.errors.confirmpassword && (
                                          <div className='error'>{formik.errors.confirmpassword}</div>
                                        )}
          {password_text=="password"?<Icon className='eye-password' path={mdiEyeOffOutline} size={1}  onClick={() => password_hide_show()} />:<Icon className='eye-password' path={mdiEyeOutline} size={1}  onClick={() => password_hide_show()}  />}                 
          </div>  



                </div>               

                <div className='form-btn-part'>
              

               
                <button type="submit" className='green-btn-square-form'>Complete</button>
             
                {/* <NavLink to="/otp-registration" className='green-btn-square-form'>Next</NavLink> */}
                </div>                            
              </Form>
            </div>
          </Col>        
        </Row>   
       </Container>
      </div> 
    </Main>
  )
}
