import React, { useRef } from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { NavLink } from 'react-bootstrap';

export default function Footer() {

  return (
    <>
    <div className='white-bg-blank'></div>
    <footer className='dark-bg-black'>
      <Container>
      <Row className="justify-content-center">
        <Col xs={12} md={2} lg={2} xl={2} xxl={2}>
          <div className='footer-logo' >
          <img src={require('../Assets/Image/logo.svg').default} alt='yotrader-logo' />
          </div>
        </Col>
        <Col xs={12} md={10} lg={10} xl={10} xxl={10}>        
          <ul className='footer-menu'>
            <li>
              <NavLink to="#">Contact us</NavLink>
            </li>
            <li>
              <NavLink to="#">About Trade123</NavLink>
            </li>
            <li>
              <NavLink to="#">Privacy policies and terms</NavLink>
            </li>
            <li>
              <NavLink to="#">Terms & conditions</NavLink>
            </li>
          </ul>
        </Col>
      </Row>
                   
      </Container>
    </footer>
    </>
  )
}
