import React, { useEffect, useState } from 'react'
import Main from '../Layout/Main'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ReactPaginate from 'react-paginate';
import { Form, NavLink } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { myinstantBidsgetAction } from '../Redux/Action/AutheAction';

export default function HomeLog() {
  const dispatch=useDispatch();

  const [loader,setloader]=useState(true)
  useEffect(()=>{

    dispatch(myinstantBidsgetAction(1)).then(function(data){
      if(data.payload){
       

      
          setloader(false)
      
      }
      
    })
  },[])
  const mybidlistdata=useSelector((state)=>state?.Bidslist?.myinstantbids)



  const handlePageChange = ({ selected }) => {
    setloader(true)
    dispatch(myinstantBidsgetAction(selected+1)).then(function(data){
      if(data.payload){
      
          setloader(false)
      
      }
      
    })
  };

 
  return (
    <>
       {
      loader==true &&<div className='loader'>
    <img src={require('../Assets/Image/loader.svg').default} alt='loader'/>
    </div>
    }
    <Main>
    <div className='main-wrapper mb-5'>
       <Container>  
       <div className='home-loged-hading'>     
       Welcome Back, {localStorage.getItem('name')}!
       </div>


       {
        mybidlistdata?.myinstatntbidslist?.length==0?

        (
          <Row className="justify-content-center ">
       <Col xs={12} md={6} lg={6} xl={6} xxl={6}>
       <div className='placholder-text'>
        <h2>No Vehicles Found</h2>
        <NavLink to="/new_valuation" className="green-anchor-btn">New Valuation</NavLink>
       </div>

       </Col>
        </Row> 
        ):
     
   


        <Row className="justify-content-center ">
        {
          mybidlistdata?.myinstatntbidslist?.map(function(object,i){
            return( <Col xs={12} md={6} lg={4} xl={4} xxl={4} key={i} className='mb-4'>
          <div className='box-shadow-card'>
            <div className='white-card-sm position-relative'>
         

            <div className='card-hading-size25 heading-height tooltipheading'> {object.manufacturer}  {object.model}<span className="tooltiptext">{object.model} {object.manufacturer}</span></div>
            <div className='card-pragraph-sm mb-3 text-center'>{object.year} {object.fuel} {object.colour}</div>
       
              <Row className="justify-content-center ">
              <Col xs={12} md={12} lg={12} xl={12} xxl={12}> 
                <div className='form-part mb-4'>
                  <div className='form-label-show text-center'>Our Best Trade Bid</div>
                 <div className='green-text-lg'>{  new Intl.NumberFormat('en-US', { style: 'currency',currency: 'GBP',}).format(object.price)
                    }</div>
                 <div className='black-text-label'>This valuation is valid for 4 days</div>
                </div>
              </Col>            
              <Col xs={12} md={12} lg={12} xl={12} xxl={12}> 
              <div className='form-btn-part-second mb-0'>

              {
                object.arrangecollection==false?
                <NavLink to={"/arrangecollection/"+object.vrm}>
                <Button type="submit"  className= "green-btn-square-form-second"> Arrange Collection</Button> </NavLink>
                : <Button type="submit"  className=   "purple-btn-square-form-second">  Collection Initiated</Button>
              }

             

            
             
              </div>
              </Col>
              </Row> 
            </div>          
            </div>
        </Col>)
          })
        }
       
       
        </Row>
      }

      <div className="pagination-container">
      {/* Pagination component */}
      {
        mybidlistdata?.myinstatntbidslist?.length>0 &&
      <ReactPaginate
        previousLabel={'Previous'}
        nextLabel={'Next'}
        breakLabel={'...'}
        pageCount={Math.ceil(mybidlistdata?.totalpage/10)} // Replace with the actual total number of pages
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={handlePageChange}
        containerClassName={'pagination'}
        activeClassName={'active'}
      />}
    </div>
      
        </Container>      
    </div>
    </Main>
    </>
  )
}
