import React, { useState } from 'react'
import Registration from './Registration';
import OtpRegistration from './OtpRegistration';
import PasswordRegistration from './PasswordRegistration';
// import { useDispatch } from 'react-redux';
export default function MainRegistration() {
    const [step, setStep] = useState(1);
    const [formData, setFormData] = useState({});

    const nextStep = () => {
        setStep(step + 1);
      };

    //   const dispatch = useDispatch();
      const prevStep = () => {
        setStep(step - 1);
      };

  switch(step){
    case 1:
        return (
            <Registration  formData={formData} setFormData={setFormData} nextStep={nextStep}  />
        )
    case 2:
        return (
            <OtpRegistration  formData={formData} setFormData={setFormData} nextStep={nextStep}  prevStep={prevStep}  />
        )
    case 3:
        return (
            <PasswordRegistration  formData={formData}  nextStep={prevStep} />
        )

default:
    return null;
  }
}
