import React, { useState } from 'react'
import Main from '../Layout/Main'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Form } from 'react-bootstrap';
import {  NavLink } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { saveinstantbiddetailsAction } from '../Redux/Action/AutheAction';

export default function Vehiclefound({ formData, setFormData, nextStep,prevStep }) {
  const dispatch=useDispatch()
  const [loader,setloader]=useState(false)
  const formik = useFormik({
    initialValues: {
      previous_owners: formData.previous_owners || '',
      service_history: formData.service_history || '',
    },
    validationSchema: Yup.object({
      previous_owners: Yup.string().required('Previous Owners is required'),
      service_history: Yup.string().required('Service History is required'),
     
    }),
    onSubmit: (values) => {
            setFormData({
              ...formData,
              ...values,
            });

            setloader(true)
         
            formData.price=(formData.valuation>formData.g3valuation)?formData.valuation:formData.g3valuation;
            formData.g3=formData.g3valuation;
            formData.tcgb=formData.valuation;
            formData.previous_owners=values.previous_owners;
            formData.service_history=values.service_history;
            dispatch(saveinstantbiddetailsAction(formData)).then(function(data){
            if(data.payload){
              setloader(false)
              nextStep();
            }
            })

             
          }
    
  });
  console.log(formik.errors)
  return (
    <>
    {
      loader==true &&<div className='loader'>
    <img src={require('../Assets/Image/loader.svg').default} alt='loader'/>
    </div>
    }
    <Main>
    <div className='main-wrapper mb-5'>
       <Container>  
       <div className='back-arrow-part'>     
        <NavLink onClick={()=>{
          prevStep()
        }}><img src={require('../Assets/Image/back-arrow.svg').default} alt='check' />Back</NavLink>
       </div>
       <Form onSubmit={formik.handleSubmit}>
        <Row className="justify-content-center ">
          <Col xs={12} md={7} lg={7} xl={7} xxl={7}>
            <div className='white-card box-shadow-card padding-mob10'>

            <div className='card-heading-md-second text-center '> {formData.manufacturer +" "}{formData.model} </div>

            <div className='vrm-millage-group  mb-2'>    
            <div className='label-in'>{formData.year} {formData.fuel} {formData.colour}</div>
            <div className='vrm-value '>{formData.mileage} miles</div>
            </div> 
            <div className='label-in text-center mb-5'>{formData.vrm}</div>
       
              <Row className="justify-content-center ">
              <Col xs={12} md={9} lg={9} xl={9} xxl={9}> 
                <div className='form-part mb-4'>
                  <div className='form-label-show-bold text-center'>Previous Owners</div>    
                  <ul className='checkbox-part'>
                    <li>
                    <input type='radio' className='check-input' name='previous_owners' 
                                            onChange={formik.handleChange}
                                          onBlur={formik.handleBlur}
                                        
                                          checked={(formik.values.previous_owners ||formData.previous_owners)==1?true:""}
                                          value="1"
                                           />
                               
                    <label className='box' for='1'>1</label>
                    </li>
                    <li>
                    <input type='radio' className='check-input' name='previous_owners' 
                                           onChange={formik.handleChange}
                                          onBlur={formik.handleBlur}
                                          value="2"
                                          checked={(formik.values.previous_owners ||formData.previous_owners)==2?true:""}
                                           />
                         
                    <label className='box' for='1'>2</label>
                    </li>
                    <li>
                    <input type='radio' className='check-input' name='previous_owners' 
                                           onChange={formik.handleChange}
                                          onBlur={formik.handleBlur}
                                          value="3"
                                          checked={(formik.values.previous_owners ||formData.previous_owners)==3?true:""}
                                           />
                           
                    <label className='box' for='1'>3</label>
                    </li>
                    <li>
                    <input type='radio' className='check-input' name='previous_owners' 
                                            onChange={formik.handleChange}
                                            value="4"
                                          onBlur={formik.handleBlur}
                                          checked={(formik.values.previous_owners ||formData.previous_owners)==4?true:""}
                                           />
                             
                    <label className='box' for='1'>4</label>
                    </li>
                    <li>
                    <input type='radio' className='check-input' name='previous_owners'  value="5"
                                           onChange={formik.handleChange}
                                          onBlur={formik.handleBlur}
                                          checked={(formik.values.previous_owners ||formData.previous_owners)==5?true:""}
                                           />
                            
                    <label className='box' for='1'>5</label>
                    </li> 
                  </ul>  
                  { formik.touched.previous_owners && formik.errors.previous_owners && (
                                          <div className='error'>{formik.errors.previous_owners}</div>
                                        )}             
                </div>
              
              </Col>
            
              <Col xs={12} md={8} lg={8} xl={8} xxl={8}> 
                <div className='form-part mb-4'>
                  <div className='form-label-show-bold text-center'>Service History</div>    
                  <ul className='checkbox-part'>
                    <li>
                    <input type='radio' className='check-input' name='service_history' value="Full"  checked={(formik.values.service_history ||formData.service_history)=="Full"?true:""}    onChange={formik.handleChange}
                                          onBlur={formik.handleBlur} />                  
                    <label className='box' for='2'>Full</label>
                    </li>
                    <li>
                    <input type='radio' className='check-input' name='service_history' value="Part" checked={(formik.values.service_history ||formData.service_history)=="Part"?true:""}    onChange={formik.handleChange}
                                          onBlur={formik.handleBlur} />                  
                    <label className='box' for='2'>Part</label>
                    </li>                
                    <li>
                    <input type='radio' className='check-input' name='service_history' value="None"  checked={(formik.values.service_history ||formData.service_history)=="None"?true:""}   onChange={formik.handleChange}
                                          onBlur={formik.handleBlur} />                  
                    <label className='box' for='2'>None</label>
                    </li>
                    <li>
                    <input type='radio' className='check-input' name='service_history' value="Not Due"   checked={(formik.values.service_history ||formData.service_history)=="Not Due"?true:""}   onChange={formik.handleChange}
                                          onBlur={formik.handleBlur} />                  
                    <label className='box' for='2'>Not Due</label>
                    </li> 
                  </ul> 
                  {  formik.touched.service_history && formik.errors.service_history && (
                                          <div className='error'>{formik.errors.service_history}</div>
                                        )}                  
                </div>
              </Col>
              <Col xs={12} md={8} lg={8} xl={8} xxl={8}> 
              <div className='form-btn-part-second'>
          

             <Button type="submit" className='green-btn-square-form-second'>GET THREE INSTANT BIDS</Button>
              </div>
              </Col>
              </Row>
                
              <div className='normal-text-black text-center'>Quick. Free Collection. Instant Cash.</div>
                  
            </div>
          </Col>
        </Row>
        </Form>
        </Container>      
    </div>
    </Main>
    </>
  )
}
