import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router ,Routes,Route, Navigate } from 'react-router-dom';

import HomeLog from './Vehiclefound/HomeLog';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MainRegistration from './Registration/MainRegistration';
import MainHome from './Home/MainHome';
import ResetPassword from './UpdateProfile/ResetPassword';
import UpdateProfile from './UpdateProfile/UpdateProfile';
import Forgotpassword from './Forgotpassword/Forgotpassword';
import ResetPasswordwithforgot from './Forgotpassword/ResetPasswordwithforgot';
import Arrangecollections from './ValueReturned/Arrangecollections';
import Thankuforarrangecollection from './Thankyou/Thankuforarrangecollection';
function App() {
  const Authentication= localStorage.getItem('accessToken') 

  return (
    <Router>
      <Routes>  
    
      {!Authentication && (
              <>
              
              <Route path='/' element={<MainHome />} />
              <Route path='/registration' element={<MainRegistration />} />
              <Route path='/forgot-password' element={<Forgotpassword />} />
              <Route path='/resetpassword/:id/:time' element={<ResetPasswordwithforgot />} />
              </>
                      )}

       { !Authentication ?<Route   element={<Navigate to="/" />    }  /> :<Route path='*' element={<Navigate to="/home-log" replace />}  />} 
       <Route path='/home-log' element={<HomeLog />} />
       <Route path='/new_valuation' element={<MainHome />} />
       <Route path='/arrangecollection/:vrm' element={<Arrangecollections />} />
       <Route path='/thankyou/:vrm' element={<Thankuforarrangecollection />} />
       
       <Route path='/reset-password' element={<ResetPassword />} />
       <Route path='/update-profile' element={<UpdateProfile />} />

      </Routes>
      <ToastContainer />
    </Router>
  );
}

export default App;
