import { createAsyncThunk } from '@reduxjs/toolkit';
import API, { tokenget } from '../Api/ApiConfig';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import Cookies from 'js-cookie';
export const login = createAsyncThunk(
    "login",
    async (data, thunkApi) => {
      const response = await API.post("/login", data);
      if(response.data.status==200){

        localStorage.setItem('name',response.data.data.dealership_name)
        localStorage.setItem('accessToken',response.data.data.accesstoken)
        Cookies.set('accessToken', response.data.data.accesstoken);
        window.accessToken=response.data.data.accesstoken;
     
       console.log(window.location.origin ,window.location.href , window.location.href !=window.location.origin+"/");
   
      if(window.location.origin ==window.location.href || window.location.href !=window.location.origin+"/"){
        window.location.href="/home-log"
      }

        return response.data.data.accesstoken



      }else{
        toast.error(response.data.message)
      }
     
    }
  );


  export const RegistrationAction = createAsyncThunk(
    "registration",
    async (data, thunkApi) => {
      const response = await API.post("/registration", data);
      if(response.data.status==200){
       
       
        return response.data.data;
      }else{
        toast.error(response.data.message)
      }
     
    }
  );

  export const otpVerificationAction = createAsyncThunk(
    "otpVerification",
    async (data, thunkApi) => {
      const response = await API.post("/otpVerification", data);
     
      if(response.data.status==200){
       
        return response.data;
      }else{
        toast.error(response.data.message)
      }
     
    }
  );

  export const ResendOtpAction = createAsyncThunk(
    "ResendOtp",
    async (data, thunkApi) => {
      const response = await API.post("/ResendOtp", data);
     
      if(response.data.status==200){
       
        return response.data;
      }else{
        toast.error(response.data.message)
      }
     
    }
  );

  export const UpdatepasswordAction = createAsyncThunk(
    "Updatepassword",
    async (data, thunkApi) => {
      const response = await API.post("/Updatepassword", data);
     
      if(response.data.status==200){
       
        Cookies.set('accessToken', response.data.data.accesstoken);
        localStorage.setItem('name',response.data.data.dealership_name)
        localStorage.setItem('accessToken',response.data.data.accesstoken)
        window.accessToken=response.data.data.accesstoken;
      
        return response.data;
       
       



      }else{
        toast.error(response.data.message)
      }
     
    }
  );



  export const resetpasswordAction = createAsyncThunk(
    "resetpassword",
    async (data, thunkApi) => {
      const response = await API.post("/Updatepassword", data);
     
      if(response.data.status==200){
       
        toast.success("Password Reset Successfully!.")
                return response.data
      }else{
        toast.error(response.data.message)
      }
     
    }
  );


  

  export const myinstantBidsAction = createAsyncThunk(
    "myinstantBids",
    async (data, thunkApi) => {

      try {

        
        const response = await API.post("/myinstantBids", data);
     
        if(response.data.status==200){
         
          return response.data.data;
        }else{
          toast.error(response.data.message)
        }
      } catch (error) {
        
      }
     
     
    }
  );

  export const updateProfiledetailsAction = createAsyncThunk(
    "updateProfiledetails",
    async (data, thunkApi) => {

      try {

        
        const response = await API.post("/updateProfiledetails", data);
     
        if(response.data.status==200){
         
          toast.success(response.data.message)
        }else{
          toast.error(response.data.message)
        }
      } catch (error) {
        
      }
     
     
    }
  );

  

  export const saveinstantbiddetailsAction = createAsyncThunk(
    "saveinstantbiddetailsAction",
    async (data, thunkApi) => {

      try {

       
        const response = await API.post("/saveinstantbiddetails", data);
     
        if(response.data.status==200){
         
          return response.data.data;
        }else{
          toast.error(response.data.message)
        }
      } catch (error) {
        
      }
     
     
    }
  );

  
  export const myinstantBidsgetAction = createAsyncThunk(
    "myinstantBidsget",
    async (data, thunkApi) => {

      try {
      

        const response = await API.get(`/myinstantBidsget?pageNumber=${data}`);
     
        if(response.data.status==200){

         
          return response.data.data;
        }else{
          toast.error(response.data.message)
        }
      } catch (error) {
        
      }
     
     
    }
  );
  


  export const reset_passwordAction = createAsyncThunk(
    "reset_password",
    async (data, thunkApi) => {

      try {

        
        const response = await API.post("/reset_password", data);
     
        if(response.data.status==200){
         
          toast.success(response.data.message)
        }else{
          toast.error(response.data.message)
        }
      } catch (error) {
        
      }
     
     
    }
  );



  export const MyinstantBidsvaluesdAction = createAsyncThunk(
    "MyinstantBidsvalues",
    async (data, thunkApi) => {

      try {


        const response = await API.post("/MyinstantBidsvalues", data);
     
        if(response.data.status==200){
         
        return response.data.data
        }else{
          toast.error(response.data.message)
        }
      } catch (error) {
        
      }
     
     
    }
  );


  

  export const profiledetailsAction = createAsyncThunk(
    "profiledetails",
    async (data, thunkApi) => {

      try {

   
        const response = await API.get("/profiledetails");
     
        if(response.data.status==200){
         
          return response.data.data;
        }else{
          toast.error(response.data.message)
        }
      } catch (error) {
        
      }
     
     
    }
  );
  
  export const changePasswordAction = createAsyncThunk(
    "changePassword",
    async (data, thunkApi) => {

      try {

    
        const response = await API.post("/changePassword",data);
     
        if(response.data.status==200){
          toast.success(response.data.message)
          return response.data.data;
        }else{
          toast.error(response.data.message)
        }
      } catch (error) {
        
      }
     
     
    }
  );
  
  