import React from 'react'
import Main from '../Layout/Main'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { Form, NavLink } from 'react-router-dom';
import { Button } from 'react-bootstrap';

export default function Thankyou({ formData, setFormData, nextStep }) {
  return (
    <>
    
    <Main>
    <div className='main-wrapper'>
       <Container>  
       <div className='back-arrow-part'>     
        <NavLink onClick={()=>{
          setFormData({})
          nextStep()
        }}><img src={require('../Assets/Image/back-arrow.svg').default} alt='check'/>Back</NavLink>
       </div>
        <Row className="justify-content-center ">
          <Col xs={12} md={7} lg={7} xl={7} xxl={7}>
          <div className='box-shadow-card mb-3'>
            <div className='white-card'>
            <div className='card-heading-md-second text-center '> {formData.manufacturer +" "}{formData.model} </div>
            <div className='vrm-millage-group  mb-2'>    
            <div className='label-in'>{formData.year} {formData.fuel} {formData.colour}</div>
            <div className='vrm-value '>{formData.mileage} miles</div>
            </div> 
            <div className='label-in text-center mb-5'>{formData.vrm}</div>
  
       
              <Row className="justify-content-center ">
              <Col xs={12} md={4} lg={12} xl={12} xxl={12}> 
                <div className='form-part mb-4'>
                 <div className='black-text-lg mb-3'>Thank you</div>
                 <div className='black-text-label'>A member of “{(formData.valuation>formData.g3valuation)?"The Car Buying Group":"G3"}” will call to arrange collection</div>
                </div>
              </Col>
              </Row> 
            </div>
            <div className='gry-bg-card m-minus-top'>
            <div className='gry-list-part'>
            <div className='gry-list-heading'>Our Assumptions</div>
            <ul className='gry-list-points'>
                <li>
                    <img src={require('../Assets/Image/check-green.svg').default} alt='check'/>
                    <p>It hasn’t got a personalised reg plate</p>
                </li>
                <li>
                    <img src={require('../Assets/Image/check-green.svg').default} alt='check'/>
                    <p>It is not an imported car</p>
                </li>
                <li>
                    <img src={require('../Assets/Image/check-green.svg').default} alt='check'/>
                    <p>It has two sets of keys</p>
                </li>
                <li>
                    <img src={require('../Assets/Image/check-green.svg').default} alt='check'/>
                    <p>It has no damage, aesthetic or functional</p>
                </li>
                <li>
                    <img src={require('../Assets/Image/check-green.svg').default} alt='check'/>
                    <p>There is at least 2 months till the MOT is due</p>
                </li>
                <li>
                    <img src={require('../Assets/Image/check-green.svg').default} alt='check'/>
                    <p>It has not been an insurance write-off</p>
                </li>
                <li>
                    <img src={require('../Assets/Image/check-green.svg').default} alt='check'/>
                    <p>It has not been used for hire or as a taxi</p>
                </li>
                <li>
                    <img src={require('../Assets/Image/check-green.svg').default} alt='check'/>
                    <p>It has not been used for driving tuition</p>
                </li>
                <li>
                    <img src={require('../Assets/Image/check-green.svg').default} alt='check'/>
                    <p>It has not been used as an emergency vehicle (e.g Police)</p>
                </li> 
            </ul>
            </div>
            </div>
            </div>
          </Col>
        </Row>

 
        </Container>    
    </div>
    </Main>
    </>
  )
}
