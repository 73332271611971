import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Main from '../Layout/Main';
import { Form } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { ResendOtpAction, otpVerificationAction } from '../Redux/Action/AutheAction';

export default function OtpRegistration({ formData, setFormData, nextStep,prevStep }) {
  const dispatch=useDispatch()
  const formik = useFormik({
    initialValues: {
      otp: formData.otp || '',
    },
    validationSchema: Yup.object({
      otp: Yup.number()
      .test('is-integer', 'Otp must be an integer', value => Number.isInteger(value))
      .min(1000, 'Otp must be a 4-digit number').max(9999, 'Otp must be a 4-digit number').required('Otp is required'),
     
    }),
    onSubmit: values => {
     
      values.user_id=formData.user_id;
      dispatch(otpVerificationAction(values)).then(function(data){
        if(data.payload){
        setFormData({
          ...formData,
          ...values,
        });
        nextStep();
      }
      })
   

    
    },
  });


  const send_again=()=>{
    dispatch(ResendOtpAction({
      
      user_id:formData.user_id
      
    }))
    

  }
  return (
    <Main >
      <div className='main-wrapper'>
       <Container>  
       {/* <div className='back-arrow-part'>     
       <NavLink ><img src={require('../Assets/Image/back-arrow.svg').default} alt='check' onClick={()=>{
          prevStep()
        }}/>Back</NavLink>
       </div> */}
        <Row className="justify-content-center">
          <Col xs={12} md={7} lg={7} xl={7} xxl={7}>
            <div className='white-card'>
              <div className='card-heading-md'>Trade123 it’s shockingly simple</div>
              <div className='card-pragraph-md'>We’ve sent a text message to  {formData.mobile_number}</div>
              <div className='card-pragraph-md'>Enter it below to continue</div>
            {/* Step Follow */} 
              <ul className='step-follow'>
                <li>
                  <div className='number-show fill-circle'>1</div>
                  <div className='step-title'>Your Details</div>
                </li>
                <li>
                  <div className='number-show fill-circle'>2</div>
                  <div className='step-title'>Verification</div>
                </li>
                <li>
                  <div className='number-show'>3</div>
                  <div className='step-title'>Security</div>
                </li>
              </ul>
              <Form onSubmit={formik.handleSubmit}>
                <div className='form-part mb-4'>
                  <div className='form-label-show-bold text-center'>OTP</div>
                  <input type="text" placeholder='Enter your code here' className='form-input-show'
                    name='otp'
                                         value={formik.values.otp}   onChange={formik.handleChange}
                                          onBlur={formik.handleBlur}
                                           />
                  {formik.touched.otp && formik.errors.otp && (
                                          <div className='error'>{formik.errors.otp}</div>
                                        )}
                </div>

                <div className='normal-text-black text-center'>Not received a code?</div>
                <div className='bold-text-black text-center mt-2'>
                <NavLink to="" className="sendagain"  onClick={()=>{
                  send_again()
                }}>Send again</NavLink>
                </div>

                <div className='form-btn-part'>
             
                <button type="submit" className='green-btn-square-form'>Next</button>
              
             
              
                </div>                            
                </Form>
            </div>
          </Col>        
        </Row>   
       </Container>
      </div>  
    </Main>
    
  )
}
