import React, { useState } from 'react'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Icon from '@mdi/react';
import { mdiEyeOutline } from '@mdi/js';
import { mdiEyeOffOutline } from '@mdi/js';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { NavLink } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { login } from '../Redux/Action/AutheAction';

export default function Header() {
  const [show,setShow] =useState(false)
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [password_text, Setpassword] = useState('password');
const dispatch=useDispatch();
  const formik = useFormik({
    initialValues: {
      email:  '',
      password:  '',
    },
    validationSchema: Yup.object({
      email: Yup.string().required('Email is required'),
      password: Yup.string().required('Password is required'),
     
    }),
    onSubmit: (values) => {
     
      dispatch(login(values)).then(function(data){
        if(data.payload){
          setShow(false)
        }
        
      })
      
    },
  });


const Authentication= localStorage.getItem('accessToken') 

const password_hide_show = () => {
  Setpassword(password_text == 'input' ? 'password' : 'input');
};

  return (
    <>
    {
      Authentication =='undefined'||Authentication ==null?
  
      <Navbar collapseOnSelect expand="lg" className="dark-black-bg">
      <Container>
      <Navbar.Brand href="/new_valuation" className='logo-header'>
      <img src={require('../Assets/Image/logo.svg').default} alt='logo'/>
      </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav" className='navbar-collapse-mobile'>
          <Nav className="ms-auto header-menu">
            <NavLink to="#" className='mr-c-50 white-link' onClick={handleShow}>Sign in</NavLink>
            <NavLink to="/registration" className='green-btn mobile-green-btn'>Sign up now</NavLink>            
          </Nav>        
        </Navbar.Collapse>
      </Container>
    </Navbar>  
:
    <Navbar collapseOnSelect expand="lg" className="dark-black-bg">
      <Container>
      <Navbar.Brand href="/new_valuation" className='logo-header'>
      <img src={require('../Assets/Image/logo.svg').default} alt='logo'/>
      </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav" className='navbar-collapse-mobile'>
          <Nav className="ms-auto header-menu">
          <NavLink to="/home-log" className='mr-c-50 white-link'>My Valuations</NavLink>
          <NavLink to="/update-profile" className='mr-c-50 white-link'>Account</NavLink>  
            <NavLink to="/new_valuation" className='mr-c-30 purple-btn'>New Valuation</NavLink>            
            <NavLink  className='green-btn' onClick={()=>{
              localStorage.clear();
              window.location.href="/"
            }}>Log Out</NavLink>          
          </Nav>        
        </Navbar.Collapse>
      </Container>
    </Navbar>  


  }
    
    {/* /*login popup*/}
    <Modal size="md" show={show} centered onHide={handleClose} className='login-form'>  
    <Modal.Body>
      <Form onSubmit={formik.handleSubmit}>
      <div className='card-heading-md mb-5'>Log in and get instant bids!</div>      
      <div className='form-part mb-4'>
        <div className='form-label-show'>Email</div>
        <input type="text" placeholder='Enter your email here' className='form-input-show' name='email'  value={formik.values.email}  
                    onChange={formik.handleChange}
                  onBlur={formik.handleBlur} />
                  {formik.touched.email && formik.errors.email && (
                                          <div className='error'>{formik.errors.email}</div>
                                        )}
      </div>
      <div className='form-part mb-4'>
        <div className='form-label-show'>Password</div>
        <div className='password-group-input'>
        <input  type={password_text} placeholder='Enter your password here' className='form-input-show password-filed' name='password'  value={formik.values.password}  
                    onChange={formik.handleChange}
                  onBlur={formik.handleBlur}                     
                  />
                  {formik.touched.password && formik.errors.password && ( <div className='error'>{formik.errors.password}</div>)}
                 
              {password_text=="password"?<Icon className='eye-password' path={mdiEyeOffOutline} size={1}  onClick={() => password_hide_show()} />:<Icon className='eye-password' path={mdiEyeOutline} size={1}  onClick={() => password_hide_show()}  />}                 
        </div>
        
      </div>


      <div className='toggle-switch-part'>
      <div className='toggle-left'>
      <Form.Check type="switch" id="custom-switch" label="Remember me"/>      
      </div>
      <div className='rember'>
      <NavLink to="/forgot-password" className='blue-link'>Forgot password?</NavLink>
      </div>
      </div> 
      <div className='form-btn-part'>
        <button type="submit" className='green-btn-square-form'>Sign In</button>
        <div className='gry-text-light'>Not a member yet?</div>
        <NavLink to="/registration">
        <button type="button" className='black-outline-btn-square-form'>Sign Up</button></NavLink>
      </div>       
     
      </Form>
    </Modal.Body>  
  </Modal>
  </>
  )
}
