import React from 'react'
import Main from '../Layout/Main'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { Col, Container, Form, Row } from 'react-bootstrap';
import { reset_passwordAction } from '../Redux/Action/AutheAction';
export default function Forgotpassword() {

    const dispatch=useDispatch();
  const formik = useFormik({
    initialValues: {
      email:  '',
      
    },
    validationSchema: Yup.object({
      email: Yup.string().required('Email is required'),
     
     
    }),
    onSubmit: (values) => {
     dispatch(reset_passwordAction(values))

        
      
      
    },
  });

  return (
   <Main>

<div className='main-wrapper mb-5'>
<Container>
<Form onSubmit={formik.handleSubmit}>
        <Row className="justify-content-center mt-5">
        <Col xs={12} md={5} lg={5} xl={5} xxl={5}>
        <div className='white-card box-shadow-card padding-mob10'>
            <div className='card-heading-md-second text-center mb-5'>Forgot Password?</div>
            <div className='form-part mb-4'>
        <div className='form-label-show'>Email</div>
        <input type="text" placeholder='Enter your email here' className='form-input-show' name='email'  value={formik.values.email}  
          onChange={formik.handleChange}
        onBlur={formik.handleBlur} />
        {formik.touched.email && formik.errors.email && (
        <div className='error'>{formik.errors.email}</div>
        )}
      </div>
      <div className='form-btn-part'>
        <button type="submit" className='green-btn-square-form'>Submit</button>
      </div>      
     
            </div>
               
        </Col>
        </Row>
        </Form>
</Container>
</div>

      
     
   </Main>
  )
}
