import React from 'react'

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Main from '../Layout/Main';
import { Form } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { RegistrationAction } from '../Redux/Action/AutheAction';

export default function Registration({ formData, setFormData, nextStep }) {
const dispatch=useDispatch();
  const formik = useFormik({
    initialValues: {
      name: formData.name || '',
      dealership_name: formData.dealership_name || '',
      email: formData.email || '',
      confirmemail: formData.confirmemail || '',
      mobile_number: formData?.mobile_number || '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Name is required'),
      dealership_name: Yup.string().required('Dealership Name is required'),
      email: Yup.string().email().required('Email is required'),
      mobile_number: Yup.string().required('Mobile Number is required'),
     confirmemail:Yup .string().required('Confirm Email is required') .test(
      'email-match',
      'Email must match',
      function (value) {
        // Convert both values to lowercase before comparison
        const email = this.resolve(Yup.ref('email')).toLowerCase();
        const confirmEmail = value.toLowerCase();

        // Compare the lowercase values for case-insensitive comparison
        return email === confirmEmail;
      }
    )
    }),
    onSubmit: values => {

      values.mobile_number="+44"+values.mobile_number;
      dispatch(RegistrationAction(values)).then(function(data){
      if(data.payload){
        values.user_id=data.payload._id

        
        setFormData({
          ...formData,
          ...values,
        });
        nextStep();
  
      }
    })
      // Handle step 1 submission
  
    },
  });



  return (
    <Main >
      <div className='main-wrapper mb-5'>
      <Container>

        <Row className="justify-content-center">
          <Col xs={12} md={7} lg={7} xl={7} xxl={7}>
            <div className='white-card'>
              <div className='card-heading-md'>Trade123 it’s shockingly simple</div>
              <div className='card-pragraph-md'>Just one quick form to get access to instant trade bids.</div>
              <div className='card-pragraph-md'>To qualify for an account, you’ll need to be a registered motor trader.</div>
            {/* Step Follow */} 
              <ul className='step-follow'>
                <li>
                  <div className='number-show fill-circle'>1</div>
                  <div className='step-title'>Your Details</div>
                </li>
                <li>
                  <div className='number-show'>2</div>
                  <div className='step-title'>Verification</div>
                </li>
                <li>
                  <div className='number-show'>3</div>
                  <div className='step-title'>Security</div>
                </li>
              </ul>
              <Form  onSubmit={formik.handleSubmit}>
                <div className='form-part mb-4'>
                  <div className='form-label-show-bold'>Your Name</div>
                  <input type="text" placeholder='Type here' className='form-input-show' name='name'
                                         value={formik.values.name}   onChange={formik.handleChange}
                                          onBlur={formik.handleBlur}
                                           />
                  {formik.touched.name && formik.errors.name && (
                                          <div className='error'>{formik.errors.name}</div>
                                        )}
                </div>
                <div className='form-part mb-4'>
                  <div className='form-label-show-bold'>Dealership Name</div>
                  <input type="text" placeholder='Type here'
                   className='form-input-show'
                   name='dealership_name'
                   value={formik.values.dealership_name}  
                    onChange={formik.handleChange}
                  onBlur={formik.handleBlur} />
                  {formik.touched.dealership_name && formik.errors.dealership_name && (
                                          <div className='error'>{formik.errors.dealership_name}</div>
                                        )}
                </div>
                <div className='form-part mb-4'>
                  <div className='form-label-show-bold'>Email Address</div>
                  <input type="email" placeholder='email@email.com' className='form-input-show' name='email'  value={formik.values.email}  
                    onChange={formik.handleChange}
                  onBlur={formik.handleBlur} />
                  {formik.touched.email && formik.errors.email && (
                                          <div className='error'>{formik.errors.email}</div>
                                        )}
                </div>
                <div className='form-part mb-5'>
                  <div className='form-label-show-bold'>Confirm Email Address</div>
                  <input type="email" placeholder='email@email.com' className='form-input-show' name='confirmemail' value={formik.values.confirmemail}  
                    onChange={formik.handleChange}
                  onBlur={formik.handleBlur} />
                  {formik.touched.confirmemail && formik.errors.confirmemail && (
                                          <div className='error'>{formik.errors.confirmemail}</div>
                                        )}
                </div>
                <div className='form-part mb-4'>
                  <div className='form-label-show-bold'>Your Mobile Number</div>
                  <div className='sub-label-show'>We’ll send you a text message to confirm your account</div>
                  <div class="input-group group-input-signup">
                  <div class="input-group-prepend">
                    <span class="input-group-text" >+44</span>
                  </div>                
                  <input type="text"  className='group-input-signup' name='mobile_number' value={formik.values.mobile_number}  
                    onChange={formik.handleChange}
                  onBlur={formik.handleBlur} />  
                </div>
                {formik.touched.mobile_number && formik.errors.mobile_number && (
                                          <div className='error'>{formik.errors.mobile_number}</div>
                                        )}
                 
                </div> 

                <div className='pragraph-second'>
                <div className='card-pragraph-md'>Please ensure the details above are correct and you can make decisions regarding trading vehicles</div> 
                </div>

                <div className='form-btn-part'>

               
                <button type="submit" className='green-btn-square-form'>Next</button>   
               
                           
                </div>
                            
              </Form>
            </div>
          </Col>        
        </Row>  
      </Container>
      </div>  
    </Main>
   
  )
}
