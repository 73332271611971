import { combineReducers, configureStore } from "@reduxjs/toolkit";
import AuthSlice from "../Slice/AuthSlice";

const rootReducer = combineReducers({
 Bidslist:AuthSlice
  // Other reducers if you have them
});



export const store = configureStore({
  reducer: rootReducer,
  });