import React, { useState } from 'react'
import Main from '../Layout/Main'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Form, Modal } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { login, myinstantBidsAction, saveinstantbiddetailsAction } from '../Redux/Action/AutheAction';
import Icon from '@mdi/react';
import { mdiEyeOutline } from '@mdi/js';
import { mdiEyeOffOutline } from '@mdi/js';
export default function Home({ formData, setFormData, nextStep }) {
 
  const [show,setShow] =useState(false)
  const handleClose = () => setShow(false);
  const [loader,setloader]=useState(false)
  const handleShow = () => setShow(true);
  const [password_text, Setpassword] = useState('password');
  const dispatch=useDispatch()
  const formik = useFormik({
    initialValues: {
      vrm: formData.vrm || '',
      mileage: formData.mileage || '',
    },
    validationSchema: Yup.object({
      vrm: Yup.string().required('Vrm is required'),
      mileage: Yup.number().required('Mileage is required'),
     
    }),
    onSubmit: (values) => {
      setloader(true)
      values.vrm=values.vrm.toUpperCase();
      dispatch(myinstantBidsAction(values)).then(function(data){
      
          if(data.payload){
            setloader(false)
            
            values.g3valuation=data.payload.g3valuation;
            values.valuation=data.payload.valuation;
            values.manufacturer=data.payload.vehicle.manufacturer
             values.model=data.payload.vehicle.model
             values.body=data.payload.vehicle.body
             values.colour=data.payload.vehicle.colour
             values.engine_size=data.payload.vehicle.engine_size
             values.fuel=data.payload.vehicle.fuel
             values.transmission=data.payload.vehicle.transmission
             values.year=data.payload.vehicle.year


            //  formData.type=(formData.valuation>formData.g3valuation)?"TCGB":"G3"
            //  formData.price=(formData.valuation>formData.g3valuation)?formData.valuation:formData.g3valuation;
            //  formData.g3=formData.g3valuation;
            //  formData.tcgb=formData.valuation;
 
            //  dispatch(saveinstantbiddetailsAction(formData))



            setFormData({
              ...formData,
              ...values,
            });

              nextStep();
          }
                })
      

   

    
    },
  });

  const formiklogin = useFormik({
    initialValues: {
      email:  '',
      password:  '',
    },
    validationSchema: Yup.object({
      email: Yup.string().required('Email is required'),
      password: Yup.string().required('Password is required'),
     
    }),
    onSubmit: (values) => {
      
      dispatch(login(values)).then(function(data){
        if(data.payload){
          setShow(false)
        }
        
      })
      
    },
  });
  const Authentication= localStorage.getItem('accessToken') 

  const password_hide_show = () => {
    Setpassword(password_text == 'input' ? 'password' : 'input');
  };

console.log(formiklogin.errors)


  return (
    <>
  
 

    {
      loader==true &&<div className='loader'>
    <img src={require('../Assets/Image/loader.svg').default} alt='loader'/>
    </div>
    }
    <Main>
    <div className='main-wrapper' id="BackTopScroll">
       <Container>  
       <div className='bg-cover-img bg-cover-img-mobile'>
       <div className='green-bold-text'>It’s easy as</div>
       <div className='white-bold-text'>1.2.3</div>
       </div>
        <Row className="justify-content-center ">
          <Col xs={12} md={7} lg={7} xl={7} xxl={7}>
          <Form onSubmit={formik.handleSubmit}>

        
            <div className='white-card-p-20 box-shadow-card mt-minus-150'>
            <div className='card-heading-md-second text-center'>What's your Trade Vehicle worth?</div>
            <div className='card-pragraph-sm mb-5 text-center'>Get three trade bids in seconds - it could be worth more than you think!</div>
       
              <Row className="justify-content-center ">
              <Col xs={12} md={5} lg={4} xl={3} xxl={3}> 
                <div className='form-part mb-4'>
                  <div className='form-label-show'>Registration</div>
                  <input type="text" placeholder='e.g. AB12 CDE' className='form-input-show input-center' name='vrm'
                                         value={formik.values.vrm}   onChange={formik.handleChange}
                                          onBlur={formik.handleBlur}
                                           />
                  {formik.touched.vrm && formik.errors.vrm && (
                                          <div className='error'>{formik.errors.vrm}</div>
                                        )}
                </div>
              </Col>
              <Col xs={12} md={5} lg={4} xl={3} xxl={3}> 
                <div className='form-part mb-4'>
                  <div className='form-label-show'>Current mileage</div>
                  <input type="text" placeholder='e.g. 10000' className='form-input-show input-center' 
                  name='mileage'
                  value={formik.values.mileage}  
                   onChange={formik.handleChange}
                   onBlur={formik.handleBlur}
                                           />
                  {formik.touched.mileage && formik.errors.mileage && (
                   <div className='error'>{formik.errors.mileage}</div>
                                        )}
                </div>
              </Col>            
              </Row>
              <Row className="justify-content-center ">             
              <Col xs={12} md={10} lg={8} xl={6} xxl={6}> 
              <div className='form-btn-part-second'>
           
       

            {Authentication =='undefined'||Authentication ==null?
            
            <Button type="button" className='green-btn-square-form-second' onClick={()=>{
             setShow(true)
             }}>Get My Instant Bids</Button>  : <Button type="submit" className='green-btn-square-form-second' >Get My Instant Bids</Button>  
           
           }
              </div>
              </Col>
              </Row>
                
              <div className='normal-text-black text-center'>Quick. Free Collection. Instant Cash.</div>
                  
            </div>
            </Form>
          </Col>
        </Row>

        <div className='how-to-use'>
        <Row className="justify-content-center ">
          <Col xs={12} md={4} lg={4} xl={4} xxl={4}>
            <div className='colum-card text-center border-right-colum1'>
              <div className='icon-how'>
              <img src={require('../Assets/Image/register.svg').default} alt='Register'/>
              </div>
              <div className='how-content'>1. Register</div>
              <div className='how-content'>We will review and approve your details asap.</div>
            </div>
          </Col>
          <Col xs={12} md={4} lg={4} xl={4} xxl={4}>
            <div className='colum-card text-center border-right-colum1'>
              <div className='icon-how'>
              <img src={require('../Assets/Image/bids.svg').default} alt='Get Three Instant Trade Bids'/>
              </div>
              <div className='how-content'>2. Get Three Instant Trade Bids</div>
              <div className='how-content'>Enter Reg and a few basic details to get your instant trade bids.</div>
            </div>
          </Col>
          <Col xs={12} md={4} lg={4} xl={4} xxl={4}>
            <div className='colum-card text-center'>
              <div className='icon-how'>
              <img src={require('../Assets/Image/payment.svg').default} alt='Free Collection and instant payment'/>
              </div>
              <div className='how-content'>3. Free Collection and instant payment</div>
              <div className='how-content'>Nationwide free collection and payment before the vehicle leaves your site.</div>
            </div>
          </Col>
        </Row>
        </div>
        </Container>
        <div className='up-arrow-section'>
        <button className="dark-blue-link-btn" onClick={()=>{
           window.scrollTo({ top: 0, behavior: 'smooth' })
        }}><img src={require('../Assets/Image/up-arrow.svg').default} alt='Free Collection and instant payment'/></button>
        <button   className="dark-blue-link-btn" onClick={()=>{
           window.scrollTo({ top: 0, behavior: 'smooth' })
        }}>Back to top</button>
        </div>
    </div>

          {/* /*login popup*/}
          <Modal size="md" show={show} centered onHide={handleClose} className='login-form'>  
    <Modal.Body>
      <Form onSubmit={formiklogin.handleSubmit}>
      <div className='card-heading-md mb-5'>Log in and get instant bids!</div>      
      <div className='form-part mb-4'>
        <div className='form-label-show'>Email</div>
        <input type="text" placeholder='Enter your email here' className='form-input-show' name='email'  value={formiklogin.values.email}  
                    onChange={formiklogin.handleChange}
                  onBlur={formiklogin.handleBlur} />
                  {formiklogin.touched.email && formiklogin.errors.email && (
                                          <div className='error'>{formiklogin.errors.email}</div>
                                        )}
      </div>
      <div className='form-part mb-4'>
        <div className='form-label-show'>Password</div>
        <div className='password-group-input'>
        <input  type={password_text} placeholder='Enter your password here' className='form-input-show password-filed' name='password'  value={formiklogin.values.password}  
                    onChange={formiklogin.handleChange}
                  onBlur={formiklogin.handleBlur}                     
                  />
                  {formiklogin.touched.password && formiklogin.errors.password && ( <div className='error'>{formiklogin.errors.password}</div>)}
                 
              {password_text=="password"?<Icon className='eye-password' path={mdiEyeOffOutline} size={1}  onClick={() => password_hide_show()} />:<Icon className='eye-password' path={mdiEyeOutline} size={1}  onClick={() => password_hide_show()}  />}                 
        </div>
      </div>

      <div className='toggle-switch-part'>
      <div className='toggle-left'>
      <Form.Check type="switch" id="custom-switch" label="Remember me"/>      
      </div>
      <div className='rember'>
      <NavLink to="/forgot-password" className='blue-link'>Forgot password?</NavLink>
      </div>
      </div> 
      <div className='form-btn-part'>
        <button type="submit" className='green-btn-square-form'>Complete</button>
        <div className='gry-text-light'>Not a member yet?</div>
        <NavLink to="/registration">
        <button type="button" className='black-outline-btn-square-form'>Sign Up</button></NavLink>
      </div>       
     
      </Form>
    </Modal.Body>  
  </Modal>
  <section className='coming-soon'>
    <div className='container container1200'>
    <div className='bg-img-comming-soon'>
    <h1>Our app is</h1> 
     <h1 className='green-text-ptheme'>coming soon!</h1> 
     {/* <p>Stay tuned & Follow for more!</p>       */}
     <img src={require('../Assets/Image/coming-soon.png')} alt='yotrader-logo'  className='coming-soon-img'/>                  
    </div>                  
    </div>
    </section>
    </Main>
    </>

  )
}
