import React, { useState } from 'react'
import Main from '../Layout/Main'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { Col, Container, Form, Row } from 'react-bootstrap';
import Icon from '@mdi/react';
import { mdiEyeOutline } from '@mdi/js';
import { mdiEyeOffOutline } from '@mdi/js';
import { useNavigate, useParams } from 'react-router-dom';
import {  resetpasswordAction } from '../Redux/Action/AutheAction';
export default function ResetPasswordwithforgot() {
  const [password_text, Setpassword] = useState('password');
    const dispatch=useDispatch();
    const navigate=useNavigate();
  const {id,time}=useParams();
  console.log(id)
    const formik = useFormik({
      initialValues: {
        password:  '',
        confirmpassword: '',
      },
      validationSchema: Yup.object({
        password: Yup.string().required('Password is required'),
        confirmpassword:Yup .string().required('Confirm Password is required').oneOf([Yup.ref('password'), null], 'Password must match') 
       
      }),
      onSubmit: values => {
        values.user_id=id;
      dispatch(resetpasswordAction(values)).then(function(){
        navigate('/')
      })
 
  
      },
    });

    const password_hide_show = () => {
      Setpassword(password_text == 'input' ? 'password' : 'input');
    };

  return (
   <Main>

<div className='main-wrapper mb-5'>
<Container>
<Form onSubmit={formik.handleSubmit}>
        <Row className="justify-content-center ">
        <Col xs={12} md={5} lg={5} xl={5} xxl={5}>
        <div className='white-card box-shadow-card padding-mob10'>
            <div className='card-heading-md-second text-center mb-5'>Reset Password?</div>
            <div className='form-part mb-4'>
        <div className='form-label-show'>New Password</div>
        <div className='password-group-input'>
                  <input  type={password_text} placeholder='Enter your password here' className='form-input-show' name='password'  value={formik.values.password}  
                    onChange={formik.handleChange}
                  onBlur={formik.handleBlur} />
                  {formik.touched.password && formik.errors.password && (
                                          <div className='error'>{formik.errors.password}</div>
                                        )}
                                        {password_text=="password"?<Icon className='eye-password' path={mdiEyeOffOutline} size={1}  onClick={() => password_hide_show()} />:<Icon className='eye-password' path={mdiEyeOutline} size={1}  onClick={() => password_hide_show()}  />}                 
          </div>  
      </div>
      <div className='form-part mb-4'>
        <div className='form-label-show'>Confirm Password</div>
        <div className='password-group-input'>
                  <input  type={password_text} placeholder='Enter your password here' className='form-input-show' name='confirmpassword'  value={formik.values.confirmpassword}  
                    onChange={formik.handleChange}
                  onBlur={formik.handleBlur} />
                  {formik.touched.confirmpassword && formik.errors.confirmpassword && (
                                          <div className='error'>{formik.errors.confirmpassword}</div>
                                        )}
                                        {password_text=="password"?<Icon className='eye-password' path={mdiEyeOffOutline} size={1}  onClick={() => password_hide_show()} />:<Icon className='eye-password' path={mdiEyeOutline} size={1}  onClick={() => password_hide_show()}  />}                 
          </div>  
      </div>
      <div className='form-btn-part'>
        <button type="submit" className='green-btn-square-form'>Submit</button>
      </div>      
     
            </div>
               
        </Col>
        </Row>
        </Form>
</Container>
</div>

      
     
   </Main>
  )
}
