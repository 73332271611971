import React, { useState } from 'react'
import Home from './Home';
import Vehiclefound from '../Vehiclefound/Vehiclefound';
import ValueReturned from '../ValueReturned/ValueReturned';
import Thankyou from '../Thankyou/Thankyou';

export default function MainHome() {
    const [step, setStep] = useState(1);
    const [formData, setFormData] = useState({});

    const nextStep = () => {
        setStep(step + 1);
      };

    //   const dispatch = useDispatch();
      const prevStep = () => {
        setStep(step - 1);
      };

      const firststep = () => {
        setStep(1);
      };

  switch(step){
    case 1:
        return (
            <Home  formData={formData} setFormData={setFormData} nextStep={nextStep}   />
        )
    case 2:
        return (
            <Vehiclefound  formData={formData} setFormData={setFormData} nextStep={nextStep} prevStep={prevStep}   />
        )
    case 3:
        return (
            <ValueReturned  formData={formData} setFormData={setFormData} nextStep={nextStep} prevStep={prevStep}  />
        )
        case 4:
            return (
                <Thankyou  formData={formData} setFormData={setFormData}  nextStep={firststep}  />
            )
default:
    return null;
  }
}
